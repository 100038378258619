<template>
  <b-row>
    <b-col v-if="!showForm" cols="12">
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-2">
          <b-card-header header-tag="header" class="p-1" role="tab">
            Filtro
          </b-card-header>
          <b-collapse
            id="accordion-1"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text>
                <b-row>
                  <b-col cols="12" sm="6" lg="3">
                    <b-form-group
                      label="Periodo Inicial"
                      label-for="v-dataInicio"
                    >
                      <flat-pickr
                        v-model="filter.dataInicial"
                        name="fieldDate"
                        class="form-control"
                        :config="{
                          altInput: true,
                          altFormat: 'd/m/Y',
                          enableTime: false,
                          dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="6" lg="3">
                    <b-form-group label="Periodo Final" label-for="v-dataFinal">
                      <flat-pickr
                        v-model="filter.dataFinal"
                        name="fieldDate"
                        class="form-control"
                        :config="{
                          altInput: true,
                          altFormat: 'd/m/Y',
                          enableTime: false,
                          dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                        }"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group label="Nome" label-for="v-nome">
                      <b-form-input
                        id="v-nome"
                        v-model="filter.nome"
                        placeholder="nome"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-1"
                      @click="filtrar"
                    >
                      Filtrar
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="warning"
                      @click="limpar"
                    >
                      Limpar
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </b-col>
    <b-col cols="12">
      <b-card v-if="!showForm" title="Agendamentos">
        <div class="custom-search d-flex justify-content-start">
          <b-button variant="primary" @click="addNew()"> Incluir </b-button>
        </div>
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">{{ $t('Search') }}</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @input="handleSearch"
              />
            </div>
          </b-form-group>
        </div>
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="listaAgendamentos"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
          :total-rows="totalRecords"
          :is-loading.sync="isLoading"
          @on-sort-change="onSortChange"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="edit(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>

                  <!-- <b-dropdown-item @click="remove(props.row)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item> -->
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="tableConfigs.size"
                  :options="[tableConfigs.size]"
                  class="mx-1"
                  @input="handleChangePage"
                />
                <span class="text-nowrap">
                  of {{ tableConfigs.totalPages }} entries
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="tableConfigs.totalElements"
                  :per-page="tableConfigs.size"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
      <Form v-else @cancelar="cancelar" />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BButton,
  BCardText,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BDropdown,
  BDropdownItem
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import mixinsGlobal from '@/mixins'
import Form from '../form/Form.vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import _ from 'lodash'

export default {
  name: 'ContasPagar',
  directives: {
    Ripple
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BCollapse,
    BButton,
    BFormGroup,
    Form,
    BFormInput,
    flatPickr,
    VueGoodTable,
    BFormSelect,
    BPagination,
    BDropdown,
    BDropdownItem
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('agendamentoModule', {
      listaAgendamentos: (state) => state.listaAgendamentos
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  async mounted() {
    this.isLoading = true
    const date = new Date()
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    const firstDayDate = firstDay.toISOString().split('T')[0]

    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const lastDayDate = lastDay.toISOString().split('T')[0]

    this.filter = {
      dataInicial: firstDayDate,
      dataFinal: lastDayDate,
      nome: ''
    }

    const [year, month, day] = firstDayDate.split('-')
    const dataInicio = `${day}-${month}-${year}`

    const [lastYear, LastMonth, LastDay] = lastDayDate.split('-')
    const dataFim = `${LastDay}-${LastMonth}-${lastYear}`

    const filtro = {
      dataInicial: dataInicio,
      dataFinal: dataFim,
      nome: ''
    }
    const obj = {
      filter: filtro,
      pageConfig: this.pageConfig
    }
    const result = await this.$store.dispatch('agendamentoModule/filtro', obj)
    this.pageConfig.page = result.data.pageable.pageNumber
    this.tableConfigs.last = result.data.last
    this.tableConfigs.totalPages = result.data.totalPages
    this.tableConfigs.totalElements = result.data.totalElements
    this.tableConfigs.size = result.data.size
    this.tableConfigs.number = result.data.number
    this.isLoading = false
  },
  data() {
    return {
      tableConfigs: {
        last: false,
        totalPages: 5,
        totalElements: 41,
        size: 10,
        number: 0
      },
      pageConfig: {
        page: 0,
        size: 10,
        sort: 'descricao',
        order: 'asc'
      },
      pageLength: 10,
      isLoading: false,
      totalRecords: 0,
      searchTerm: '',
      filter: {
        dataInicial: '',
        dataFinal: '',
        nome: ''
      },
      ehAssociado: false,
      showCpfAssociado: true,
      showForm: false,
      columns: [
        {
          label: 'Data Inicial',
          field: 'dataInicial',
          formatFn: this.formatDateGrid
        },
        {
          label: 'Data Final',
          field: 'dataFinal',
          formatFn: this.formatDateGrid
        },
        {
          label: 'Hora Inicial',
          field: 'horaInicial'
        },
        {
          label: 'Hora Final',
          field: 'horaFinal'
        },
        {
          label: 'Profissional',
          field: 'profissional.nome',
          sortable: false
        },
        {
          label: 'Quantidade Vagas',
          field: 'qtoVagas',
          sortable: false
        },
        {
          label: this.$t('Action'),
          field: 'action'
        }
      ]
    }
  },
  methods: {
    handleSearch: _.debounce(async function (searching) {
      this.filter.nome = searching
      if (!this.filter.dataInicial) {
        this.filter.dataInicial = null
      }
      if (!this.filter.dataFinal) {
        this.filter.dataFinal = null
      }
      this.filtrar()
    }, 500),
    pageConfigdefault() {
      this.pageConfig = {
        check: false,
        page: 0,
        size: 10,
        sort: 'dataInicial',
        order: 'asc'
      }
    },
    handleChangePage(page) {
      this.pageConfig.page = page
      this.filtrar()
    },
    onSortChange(params) {
      this.pageConfigdefault()
      this.pageConfig.sort = params[0].field
      this.pageConfig.order = params[0].type
      this.this.filtrar()
    },
    async filtrar() {
      const filter = { ...this.filter }

      if (filter.dataInicial) {
        const [year, month, day] = filter.dataInicial.split('T')[0].split('-')
        filter.dataInicial = `${day}-${month}-${year}`
      }

      if (filter.dataFinal) {
        const [year, month, day] = filter.dataFinal.split('T')[0].split('-')
        filter.dataFinal = `${day}-${month}-${year}`
      }

      this.loadTable({
        filter,
        pageConfig: this.pageConfig
      })
    },
    async loadTable(obj) {
      this.isLoading = true
      const result = await this.$store.dispatch('agendamentoModule/filtro', obj)
      this.pageConfig.page = result.data.pageable.pageNumber
      this.tableConfigs.last = result.data.last
      this.tableConfigs.totalPages = result.data.totalPages
      this.tableConfigs.totalElements = result.data.totalElements
      this.tableConfigs.size = result.data.size
      this.tableConfigs.number = result.data.number
      this.isLoading = false
    },
    limpar() {
      this.filter = {
        dataInicial: '',
        dataFinal: '',
        nome: ''
      }
      this.filtrar()
    },
    async addNew() {
      await this.$store.dispatch('agendamentoModule/reset')
      this.showForm = true
    },
    async edit(data) {
      this.$router.push({ path: `agendamento/edit/${data.id}` })
    },
    async cancelar() {
      this.showForm = false
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
